<template>
  <div class="formulaDetail">
    <el-form class="form" label-width="auto">
      <el-form-item>
        <h1 style="margin-right: 20px;">{{ data.formula.id ? '编辑' : '新增' }}公式</h1>
        <el-button-group>
          <el-button @click="router.go(-1)">返回</el-button>
          <el-button type="primary" @click="add()" v-if="!data.formula.id">保存</el-button>
          <el-button type="primary" @click="save()" v-if="data.formula.id">保存</el-button>
        </el-button-group>
      </el-form-item>
      <el-form-item label="公式使用场景">
        <el-select v-model="data.formula.model" filterable :disabled="data.formula.id != null">
          <el-option v-for="formulaModelItem in show.formulaModelList" :key="formulaModelItem.model" :label="formulaModelItem.name" :value="formulaModelItem.model" />
        </el-select>
      </el-form-item>
      <el-form-item label="公式名称">
        <el-input v-model="data.formula.name" :max="100" />
      </el-form-item>
      <el-form-item label="语法提示">
        <el-button v-show="show.tip == false" @click="show.tip = true">显示提示</el-button>
        <el-descriptions border :column="4" v-show="show.tip == true" style="width: 100%;">
          <el-descriptions-item :span="1" label="加">+</el-descriptions-item>
          <el-descriptions-item :span="1" label="减">-</el-descriptions-item>
          <el-descriptions-item :span="1" label="乘">*</el-descriptions-item>
          <el-descriptions-item :span="1" label="除">/</el-descriptions-item>
          <el-descriptions-item :span="2" label="四舍五入取整">Math.round(参数)</el-descriptions-item>
          <el-descriptions-item :span="2" label="保留2为小数">Math.round(参数*100)/100</el-descriptions-item>
          <el-descriptions-item :span="2" label="变量声明">var 变量名 = 0</el-descriptions-item>
          <el-descriptions-item :span="2" label="变量赋值">变量 = 0</el-descriptions-item>
          <el-descriptions-item :span="4" label="分支语句">
            if (判断条件) <br>
            { 脚本; } <br>
            else if (上一条件不满足时新条件) <br>
            { 脚本; }
          </el-descriptions-item>
          <el-descriptions-item :span="4" label="注意">
            小数运算结果需要四舍五入，再进行大小、相等的比较，否则可能不准确！
          </el-descriptions-item>
          <el-descriptions-item :span="2" label="是否相等">参数1==参数2</el-descriptions-item>
          <el-descriptions-item :span="2" label="是否不等">参数1!=参数2</el-descriptions-item>
          <el-descriptions-item :span="1" label="是否大于">&gt;</el-descriptions-item>
          <el-descriptions-item :span="1" label="大于等于">&gt;=</el-descriptions-item>
          <el-descriptions-item :span="1" label="是否小于">&lt;</el-descriptions-item>
          <el-descriptions-item :span="1" label="小于等于">&lt;=</el-descriptions-item>
          <el-descriptions-item :span="4" label="其他">
            脚本中的符号都必须是英文的每句脚本结束时要有分号结尾<br>
            变量、符号之间的空格换行不影响脚本执行<br>
            脚本中不支持百分数，百分数请转成小数形式，例如用0.02代表2%
          </el-descriptions-item>
        </el-descriptions>
      </el-form-item>
      <el-form-item label="可使用参数" v-if="formulaParams().length > 0">
        <el-link style="margin-left: 10px;" v-for="param in formulaParams()" :key="param" @click="util.clipBoard(param)">
          {{ param }}
        </el-link>
      </el-form-item>
      <el-form-item label="结果赋值到">
        <el-link style="margin-left: 10px;" @click="util.clipBoard('结果')">结果</el-link>
      </el-form-item>
      <el-form-item label="js脚本">
        <el-input class="js-code-input" type="textarea" v-model="data.formula.jsScript" :rows="10" />
      </el-form-item>
    </el-form>
  </div>
</template>

<script setup>
import { reactive, } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import api from '../../apis/api'
import cache from '../../utils/cache'
import util from '../../utils/util'

const router = useRouter()
const show = reactive({
  formulaModelList: [],
  tip: false,
})
const pageParam = cache.getObject(cache.keys.pageParam + "formulaDetail")
const data = reactive({
  formula: pageParam ? JSON.parse(JSON.stringify(pageParam)) : {},
})

api.get('/backend/formula/getModel').then(res => {
  show.formulaModelList = res.list
})

const add = () => {
  const param = JSON.parse(JSON.stringify(data.formula))
  api.post('/backend/formula/add', param).then(() => {
    ElMessage.success('保存成功')
    router.go(-1)
  })
}

const save = () => {
  const param = { id: data.formula.id }
  param.name = data.formula.name
  param.jsScript = data.formula.jsScript
  api.post('/backend/formula/set', param).then(() => {
    ElMessage.success('保存成功')
    router.go(-1)
  })
}

const formulaParams = () => {
  if (!data.formula.model) {
    return []
  }
  if (data.formula.model == 'transDedProcure') {
    return ['发货数量', '到货数量']
  }
  if (data.formula.model == 'transDedSale') {
    return ['重车数量', '皮重数量', '出库数量', '涨吨数量', '退车数量', '库存扣减', '到货数量', '运输亏吨', '最终验收']
  }
  if (data.formula.model == 'saleSettlement') {
    return ['取样数量', '全水', '内水', '灰分', '挥发分', '全硫', '固定碳', '热值兆焦', '热值大卡']
  }
  if (data.formula.model == 'dispatchPrice') {
    return ['数量', '结算费用', '结算单价', '入库单价', '上游运费单价', '下游运费单价']
  }
  if (data.formula.model == 'costPriceTax') {
    return ['数量', '结算费用', '结算单价', '入库单价', '上游运费单价', '下游运费单价', '调度费', '成本合计']
  }
  if (data.formula.model == 'salePriceXiaoxiangTax') {
    return ['数量', '结算费用', '结算单价', '入库单价', '上游运费单价', '下游运费单价', '调度费', '成本合计', '成本税', '未税成本']
  }
  if (data.formula.model == 'salePriceFujiaTax') {
    return ['数量', '结算费用', '结算单价', '入库单价', '上游运费单价', '下游运费单价', '调度费', '成本合计', '成本税', '未税成本', '销项税', '增值税', '未税单价', '增值税税负']
  }
  if (data.formula.model == 'salePriceYinhuaTax') {
    return ['数量', '结算费用', '结算单价', '入库单价', '上游运费单价', '下游运费单价', '调度费', '成本合计', '成本税', '未税成本', '销项税', '增值税', '未税单价', '增值税税负', '附加税']
  }
  if (data.formula.model == 'salePriceEstimatedCost') {
    return ['数量', '结算费用', '结算单价', '入库单价', '上游运费单价', '下游运费单价', '调度费', '成本合计', '成本税', '未税成本', '销项税', '增值税', '未税单价', '增值税税负', '附加税', '印花税']
  }
  if (data.formula.model == 'salePriceSuodeTax') {
    return ['数量', '结算费用', '结算单价', '入库单价', '上游运费单价', '下游运费单价', '调度费', '成本合计', '成本税', '未税成本', '销项税', '增值税', '未税单价', '增值税税负', '附加税', '印花税', '预估费用']
  }
}
</script>

<style lang="less">
.formulaDetail {
  margin: auto;
  max-width: 900px;

  .js-code-input textarea {
    font-family: 'Courier New', Courier, monospace;
  }
}
</style>